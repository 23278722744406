import { MdDashboard, MdCategory, MdNewspaper } from "react-icons/md";
import { TbNews } from "react-icons/tb";
import { BsBroadcast } from "react-icons/bs";

export const nav = [
  {
    title: "Dashboard",
    link: "/",
    icon: <MdDashboard />,
  },
  {
    title: "News Categories",
    link: "/news-categories",
    icon: <MdCategory />,
  },
  {
    title: "Latest News",
    link: "/news",
    icon: <TbNews />,
  },
  {
    title: "Broadcast Region",
    link: "/broadcast-regions",
    icon: <BsBroadcast />,
  },
  {
    title: "E Papers",
    link: "/e-papers",
    icon: <MdNewspaper />,
  },
  {
    title: "Marque",
    link: "/marque",
    icon: <MdNewspaper />,
  },
];
