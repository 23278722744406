import React, { useState } from "react";
import { Link, useLocation, useNavigate, useOutlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import {
  Container,
  Header,
  Sidebar,
  Sidenav,
  Content,
  Navbar,
  Nav,
  Dropdown,
} from "rsuite";
import Footer from "rsuite/Footer";
import { nav } from "../data/nav";
import { HiUserCircle } from "react-icons/hi";
import AppContent from "./AppContent";

const ProtectedLayout = () => {
  const navigate = useNavigate();
  const [expand, setExpand] = useState(false);
  const { user, logout } = useAuth();
  const outlet = useOutlet();
  const location = useLocation();
  const pathname = location.pathname;

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <Container>
      <Sidebar
        style={{ display: "flex", flexDirection: "column" }}
        width={expand ? 260 : 56}
        collapsible
        onMouseOver={() => {
          setExpand(true);
        }}
        onMouseOut={() => {
          setExpand(false);
        }}
      >
        {/* <Sidenav.Header>
          <span>{expand ? "Getmii" : "G"}</span>{" "}
        </Sidenav.Header> */}
        <Sidenav expanded={expand} appearance="subtle">
          <Sidenav.Body>
            <div className="sidenav-header">Thehsconsultancy</div>
            <Nav>
              {nav.map((item, i) => {
                if (item.menu) {
                  return (
                    <Nav.Menu
                      key={i}
                      eventKey={i + 1}
                      trigger="hover"
                      title={item.title}
                      icon={item.icon}
                      placement="rightStart"
                    >
                      {item.menu.map((item2, j) => {
                        return (
                          <Nav.Item
                            key={j}
                            eventKey="3-1"
                            onClick={() => {
                              navigate(item2.link);
                              setExpand(false);
                            }}
                          >
                            {item2.title}
                          </Nav.Item>
                        );
                      })}
                    </Nav.Menu>
                  );
                } else {
                  return (
                    <Nav.Item
                      key={i}
                      eventKey="2"
                      icon={item.icon}
                      onClick={() => {
                        navigate(item.link);
                        setExpand(false);
                      }}
                    >
                      {item.title}
                    </Nav.Item>
                  );
                }
              })}
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      </Sidebar>
      <Container>
        <Header>
          <h4>{pathname.split("/")[1].replaceAll("-", " ")}</h4>
          <Dropdown
            trigger={["hover"]}
            title={<HiUserCircle />}
            placement="bottomEnd"
          >
            <Dropdown.Item>Profile</Dropdown.Item>
            <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
          </Dropdown>
        </Header>
        <Content className="p-4">
          <AppContent />
        </Content>
      </Container>
    </Container>
  );
};

export default React.memo(ProtectedLayout);
