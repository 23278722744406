import React, { lazy } from "react";
const Dashboard = lazy(() => import("./pages/Dashboard"));
const NewsList = lazy(() => import("./pages/news/NewsList"));
const CategoriesList = lazy(() =>
  import("./pages/newsCategories/CategoriesLIst")
);
const RegionList = lazy(() => import("./pages/broadcastRegion/RegionList"));
const Epaper = lazy(() => import("./pages/ePaper/Epaper"));
const Marque = lazy(() => import("./pages/marque/Marque"));

const routes = [
  {
    path: "/",
    exact: true,
    name: "Dashboard",
    element: Dashboard,
  },
  //USER ROUTES
  {
    path: "/news-categories",
    exact: true,
    name: "News Categories",
    element: CategoriesList,
  },
  {
    path: "/news",
    exact: true,
    name: "News",
    element: NewsList,
  },
  {
    path: "/broadcast-regions",
    exact: true,
    name: "News",
    element: RegionList,
  },
  {
    path: "/e-papers",
    exact: true,
    name: "News",
    element: Epaper,
  },
  {
    path: "/marque",
    exact: true,
    name: "Marque",
    element: Marque,
  },
];

export default routes;
